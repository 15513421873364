import BaseApi from "@/includes/services/BaseApi"
import { GetBotUsersReportResponse } from 'piramis-base-components/src/components/BotUsers/types'
import { UserProfile, Subscription } from 'piramis-base-components/src/components/BotUsers/components/Users/types'

export class UsersService {
	static getBotUsers(type: string, body: { board_key: string, offset: number, limit: number, filter?: string, labels?: Array<number> }): Promise<any> {
		return BaseApi.sendRequest(type, 'GetBotUsers', body)
	}

	static getProfile(type: string, body: { board_key: string, id: string | number }): Promise<{ data: UserProfile }> {
		return BaseApi.sendRequest(type, 'GetProfile', body)
	}

	static deleteBotUserLabel(type: string, body: { board_key: string, user_id: number, label_id: number }): Promise<{ labels: { [key: number]: string }, user_id: number }> {
		return BaseApi.sendRequest(type, 'DeleteBotUserLabel', body)
	}

	static addBotUserLabel(type: string, body: { board_key: string, user_id: number, label_id: number }): Promise<{ labels: { [key: number]: string }, user_id: number }> {
		return BaseApi.sendRequest(type, 'AddBotUserLabel', body)
	}

	static setBotUserProperty(type: string, body: { board_key: string, user_id: number, property_id: number, value: string | number | boolean }): Promise<{ value: string | number | boolean  }> {
		return BaseApi.sendRequest(type, 'SetBotUserProperty', body)
	}

	static deleteBotUserProperty(type: string, body: { board_key: string, user_id: number, property_id: number }): Promise<{ value: string | number | boolean  }> {
		return BaseApi.sendRequest(type, 'DeleteBotUserProperty', body)
	}

	static createSubscription(type: string, body: { board_key: string, user_id: number, channel_id: number, duration: number }): Promise<{ data: Subscription }> {
		return BaseApi.sendRequest(type, 'CreateSubscription', body)
	}

	static deleteSubscription(type: string, body: { board_key: string, user_id: number, channel_id: number }): Promise<any> {
		return BaseApi.sendRequest(type, 'DeleteSubscription', body)
	}

	/**
	 * @param type
	 * @param body : { board_key: ключ доски, utm: опционально, id UTM метки }
	 */
	static getBotUsersReport(type: string, body: { board_key: string, utm?: string }): Promise<{ data: GetBotUsersReportResponse }> {
		return BaseApi.sendRequest(type, 'GetBotUsersReport', body)
	}

	static banUser(type, body: { board_key: string, user_id: number }): Promise<any> {
		return BaseApi.sendRequest(type, 'Ban', body)
	}

	static unbanUser(type, body: { board_key: string, user_id: number }): Promise<any> {
		return BaseApi.sendRequest(type, 'Unban', body)
	}
}
