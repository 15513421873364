








import { UseFields } from 'piramis-base-components/src/components/Pi'
import { Component } from 'vue-property-decorator'
import BotUserProfile from 'piramis-base-components/src/components/BotUserProfile/Profile.vue'
import { UserProfileConfig } from 'piramis-base-components/src/components/BotUserProfile/types'
import { UsersService } from '@/includes/services/UsersService'
import { UserProfile } from 'piramis-base-components/src/components/BotUsers/components/Users/types'
import { ShopService } from "@/includes/services/ShopService";
import { Board } from "@/includes/types/Board.types";
import { Notice } from 'piramis-base-components/src/components/Shop/Notices/types'

@Component({
  components: {
    BotUserProfile,
  }
})
export default class Profile extends UseFields {
  get board(): string {
    return this.$store.state.boardsState.activeBoard!.board
  }

  config: UserProfileConfig = {
    user_id: Number(this.$route.params.user_id),
    getProfile: (id) => new Promise(resolve => {
      UsersService.getProfile('tg', { board_key: this.board, id })
        .then(({ data }) => {
          const profile = data.subscriptions ? data : Object.assign(data, { subscriptions: [] })
          resolve(profile)
        })
    }),
    cardHelper: {
      getLabels: () => this.$store.getters.labels,
      ban: (id) => UsersService.banUser('tg', { board_key: this.board, user_id: id }),
      unban: (id) => UsersService.unbanUser('tg', { board_key: this.board, user_id: id }),
      addLabel: (user_id, label_id) => UsersService.addBotUserLabel('tg', { board_key: this.board, user_id, label_id }),
      removeLabel: (user_id, label_id) => UsersService.deleteBotUserLabel('tg', { board_key: this.board, user_id, label_id }),
      createLabel: (name) => this.$store.dispatch('createLabel', name)
    },
    tabsHelper: {
      properties: {
        getProperties: () => this.$store.getters.properties,
        setProperty: (user_id, property_id, value) => UsersService.setBotUserProperty('tg', { board_key: this.board, user_id, property_id, value }),
        removeProperty: (user_id, property_id) => UsersService.deleteBotUserProperty('tg', { board_key: this.board, user_id, property_id }),
      },
      notices: {
        getNotices: (offset, limit, customer_id) => new Promise(resolve => {
          ShopService.getNotices('tg', { board_key: this.board, offset, limit, customer_id })
            .then((res) => resolve(res as { items: Array<Notice> }))
        })
      },
      subscriptions: {
        createSubscription: (user_id, channel_id, duration) => new Promise(resolve => {
          UsersService.createSubscription('tg', { board_key: this.board, user_id, channel_id, duration }).then(({ data }) => resolve(data))
        }),
        removeSubscription: (user_id, channel_id) => UsersService.deleteSubscription('tg', { board_key: this.board, user_id, channel_id }),
        getChannelTitle: (id) => {
          const board: Board = this.$store.getters.getBoardById(this.$route.params.id)

          if (board) {
            const target = board.targets.find((t) => t.id === id)
            return target ? target.title : undefined
          }
          return undefined
        },
        getFreeChannelsOptions: (busy) => {
          const board: Board = this.$store.getters.getBoardById(this.$route.params.id)

          if (board) {
            return board.targets.filter((t) => !busy.includes(t.id))
              .map((t) => {
                return {
                  label: t.title,
                  value: t.id,
                }
              })
          }

          return []
        }
      }
    }
  }
}
